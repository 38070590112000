import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { map, catchError } from 'rxjs/operators';
import {throwError} from 'rxjs';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  baseUrl = 'https://admin.elmieme.gr/';

  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  errorData: {};


  constructor(private httpClient: HttpClient, private cookieService: CookieService, public router: Router, ) { }

  loginUser(authData) {
    const body = JSON.stringify(authData);
    return this.httpClient.post(`${this.baseUrl}authentication/login`, body, {headers: this.headers});
  }


  isLoggedIn() {
    // get logged in user
    return  this.cookieService.get('my-token');
  }

  getAuthorizationToken() {
    const currentUser = JSON.stringify(this.cookieService.get('my-token'));
  }

  logout() {
    this.cookieService.delete('my-token');
    this.router.navigate(['login']);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message
    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }

  getAuthHeaders() {
    const token = this.cookieService.get('my-token');
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`
    });
  }

  Logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }
}
