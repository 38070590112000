import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-saas-apps',
  templateUrl: './saas-apps.component.html',
  styleUrls: ['./saas-apps.component.css']
})
export class SaasAppsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
