import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { DashboardService } from '../APIServices/dashboard/dashboard.service';
import {CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import {
  Category,
  Subcategory,
  Categorisedsubs,
  Uncategorisedsubs,
  Activesubs,
  Expiredsubs,
  Catswithnosubs
} from './models/category';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  categories: Category[] = [];
  catswithnosubs: Catswithnosubs[] = [];
  subcategories: Subcategory[] = [];
  // categorisedsubs: Categorisedsubs[] = [];
  uncategorisedsubs: Uncategorisedsubs[] = [];
  activesubs: Activesubs[] = [];
  expiredsubs: Expiredsubs[] = [];
  selectCategory = null;
  editedCategory = null;

  // @Input() categories: Category[] = [];
  // @Output() selectCategory = new EventEmitter<Category>();
  // @Output() editedCategory = new EventEmitter<Category>();
  // @Output() createNewCategory = new EventEmitter();
  // @Output() deletedCategory = new EventEmitter<Category>();

  // @Input() subcategories: Subcategory[] = [];
  // @Input() categorisedsubs: Categorisedsubs[] = [];
  // @Input() uncategorisedsubs: Uncategorisedsubs[] = [];
  // @Input() activesubs: Activesubs[] = [];
  // @Input() expiredsubs: Expiredsubs[] = [];


  constructor(private api: DashboardService, private cookieService: CookieService, private router: Router) {
  }

  ngOnInit() {
    const Token = this.cookieService.get('my-token');
    if (!Token) {
      this.router.navigate(['login']);
    } else {
      this.api.getCategories().subscribe(
        (data: Category[]) => {
          this.categories = data;
        },
        error => console.log(error)
      );
    }
    if (!Token) {
      this.router.navigate(['login']);
    } else {
      this.api.categorisedsubs().subscribe(
        (data: Categorisedsubs[]) => {
          this.categories = data;
        },
        error => console.log(error)
      );
    }

    this.api.subwithnocat().subscribe(
      (data: Catswithnosubs[]) => {
        this.catswithnosubs = data;
      },
      error => console.log(error)
    );

    this.api.uncategorisedsubs().subscribe(
      (data: Uncategorisedsubs[]) => {
        this.uncategorisedsubs = data;
      },
      error => console.log(error)
    );

    this.api.subCategories().subscribe(
      (data: Subcategory[]) => {
        this.subcategories = data;
      },
      error => console.log(error)
    );

    this.api.activesubs().subscribe(
      (data: Activesubs[]) => {
        this.activesubs = data;
      },
      error => console.log(error)
    );

    this.api.expiredsubs().subscribe(
      (data: Expiredsubs[]) => {
        this.expiredsubs = data;
      },
      error => console.log(error)
    );

    // logout();
    // {
    //   this.cookieService.delete('my-token');
    //   this.router.navigate(['login']);
    // }


    // this.api.categorisedsubs().subscribe(
    //   (categorisedsubs: Categorisedsubs[]) => this.categorisedsubs = categorisedsubs,
    //   (error: any) => this.error = error
    // );
    // this.api.categories().subscribe(
    //   (category: Category[]) => this.category = category,
    //   (error: any) => this.error = error
    // );
    // this.api.subcategories().subscribe(
    //   (subcategory: Subcategory[]) => this.subcategory = subcategory,
    //   (error: any) => this.error = error
    // );
    // this.api.uncategorisedsubs().subscribe(
    //   (uncategorisedsubs: Uncategorisedsubs[]) => this.uncategorisedsubs = uncategorisedsubs,
    //   (error: any) => this.error = error
    // );
    // this.api.activesubs().subscribe(
    //   (activesubs: Activesubs[]) => this.activesubs = activesubs,
    //   (error: any) => this.error = error
    // );
    // this.api.expiredsubs().subscribe(
    //   (expiredsubs: Expiredsubs[]) => this.expiredsubs = expiredsubs,
    //   (error: any) => this.error = error
    // );

  }
}
