import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from '../APIServices/auth/login.service';



interface TokenObj {
  key: string;
  id: number;
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  login;
  errorMessage: string;
  hide = true;
  error: {};
  loginError: string;
  constructor(private loginService: LoginService, public router: Router, private cookieService: CookieService) { }

  ngOnInit() {
    this.login = {
      email: '',
      password: ''
    };
    const Token = this.cookieService.get('my-token');
    console.log(Token);
    if (Token) {
      this.router.navigate(['dashboard']);
    }
  }

  loginUser() {
    this.loginService.loginUser(this.login).subscribe(
      (result: TokenObj) => {
        console.log(result);
        this.cookieService.set('my-token', result.key,  result.id);
        localStorage.setItem('currentUser', JSON.stringify(result.key));
        // alert('user logged in');
        this.router.navigate(['dashboard']);
      },
      (err) => {
        err = [err.error.email, err.error.non_field_errors, err.error.detail];
        this.errorMessage = err;
        setTimeout(() => this.errorMessage = '', 2000);
      });
  }

}
