import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {Category, Subcategory} from '../../dashboard/models/category';


@Injectable({
  providedIn: 'root'
})

export class DashboardService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  private apiRoot = 'https://admin.elmieme.gr/';

  constructor( private httpClient: HttpClient, private cookieService: CookieService ) { }


  getCategories() {
    return this.httpClient.get<Category[]>(this.apiRoot.concat('categories/category/'), { headers: this.getAuthHeaders()});
  }
  getCategory(id: number) {
    return this.httpClient.get<Category>(`${this.apiRoot.concat('categories/category/')}${id}/`, {headers: this.getAuthHeaders()});
  }

  subCategories() {
    return this.httpClient.get(this.apiRoot.concat('categories/sub_category/'), {headers: this.getAuthHeaders()});
  }
  subwithnocat() {
    return this.httpClient.get(this.apiRoot.concat('categories/catwithnosub/'), {headers: this.getAuthHeaders()});
  }
  categorisedsubs() {
  return this.httpClient.get(this.apiRoot.concat('categories/category/'), {headers: this.getAuthHeaders()});
}
  uncategorisedsubs() {
    return this.httpClient.get(this.apiRoot.concat('categories/uncategorised_subs/'), {headers: this.getAuthHeaders()});
  }
  activesubs() {
    return this.httpClient.get(this.apiRoot.concat('categories/activesubs/'), {headers: this.getAuthHeaders()});
  }

  expiredsubs() {
    return this.httpClient.get(this.apiRoot.concat('categories/expiredsubs/'), {headers: this.getAuthHeaders()});
  }

  getAuthHeaders() {
    const Token = this.cookieService.get('my-token');
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Token ${Token}`
    });
}
}
