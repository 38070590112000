import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private apiRoot = 'http://iamfeysal.pythonanywhere.com/';

  constructor(private http: HttpClient) { }

  categorisedsubs() {
  return this.http.get(this.apiRoot.concat('categories/category'));
}
  uncategorisedsubs() {
    return this.http.get(this.apiRoot.concat('categories/category'));
  }
  activesubs() {
    return this.http.get(this.apiRoot.concat('categories/category'));
  }

  expiredsubs() {
    return this.http.get(this.apiRoot.concat('categories/category'));
  }

  categories() {
    return this.http.get(this.apiRoot.concat('categories/category'));
  }
  subcategories() {
    return this.http.get(this.apiRoot.concat('categories/sub_category'));
  }
}
